<template>
    <v-row>
    <v-col cols="12" sm="2">
	<v-text-field
        hide-details dense outlined
        :label="label"
        :ref="name"
        v-model="newValue"
        :placeholder="label"
        :reverse="true"
        @input="hasChanged($event)"
        @keypress="isAllowed($event)"
        autocomplete="new-password"
    >
    </v-text-field>
    </v-col>
    <v-col cols="12" sm="3">
	<v-text-field class="centered-input"
        hide-details dense outlined
        label="von"
        ref="von"
        v-model="von"
        placeholder="von"
        readonly
    ></v-text-field>
    </v-col>
    <v-col cols="12" sm="3">
	<v-text-field class="centered-input"
        hide-details dense outlined
        label="bis"
        ref="bis"
        v-model="bis"
        placeholder="bis"
        readonly
    ></v-text-field>
    </v-col>
    </v-row>
</template>
<script>

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
//Date.prototype.addDays=function(d){return new Date(this.valueOf()+864E5*d);};

export default {
    name: 'KWInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        reverse: { 'default': false, 'number': true, 'date': false, 'kw': true, 'decimal': true, 'euro': true, 'proz': true },
        von: '',
        bis: '',
        newValue: '',
        wky: null, wkw: null,
        dVon: null, dBis: null,
    }),
    mounted() {
        if(this.value && this.value.search("/") >= 0) {
            let res = this.value.split("/");
            if(res.length > 1) {
                this.wkw = res[0]
                this.wky = res[1]
                this.newValue = this.wkw + '/' + this.wky
            }
        } else {
            const weekNumber = new Date().getWeekNumber()
            console.log(weekNumber)
            this.wkw = parseInt(weekNumber.week, 10)
            this.wky = weekNumber.year
			this.dVon = new Date().getDateOfISOWeek(this.wkw, this.wky)
			this.von = this.dVon.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })
            this.newValue = this.wkw + '/' + this.wky
            console.log(this.dVon.addDays(7));

        }
        /*if(this.reverse[this.format]) {
            let swap = this.prefix
            this.prefix = this.suffix
            this.suffix = swap
        }
        console.log('NumberInput[' + this.name + ']:', this.format, this.reverse[this.format], this.prefix[this.format], this.suffix[this.format])*/
    },
    methods: {
        isAllowed(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[0-9\/]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },
        hasChanged(e) {
            //this.newValue = this.valuekw + '/' + this.valuejr
            //this.$emit('changed', this.newValue)
        }
    },
}
</script>
<style scoped>
.centered-input >>> input {
    text-align: center
}
</style>